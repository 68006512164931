export const updateDatabase = async (categories) => {
    const token = localStorage.getItem('authToken');
    const promises = categories.map(category => {
        const updatedData = { ...category, order: category.order }; // Preserve all data
        return fetch(`https://admin.perfettopizza.ro/api/documents/${category.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updatedData)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Failed to update the document');
            }
        }).catch(error => {
            console.error('Failed to update order:', error);
        });
    });

    try {
        await Promise.all(promises);
    } catch (error) {
        console.error('Error updating orders:', error);
    }
};
