import { useEffect, useState } from 'react';

export const useCategories = (currentId) => {
    const [categories, setCategories] = useState([]);
    const [updateEffect, setUpdateEffect] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await fetch(`https://admin.perfettopizza.ro/api/documents?subcategoryId=${currentId}`);
                const data = await response.json();
                const filtered = data.documents.filter(doc => doc.subcategories === currentId);
                setCategories(filtered);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };

        fetchDocuments();
    }, [currentId, updateEffect]);

    return [categories, setCategories, setUpdateEffect];
};
